import React, { Component } from 'react'

import { CToast, CToastBody } from '@coreui/react'
import { ReactFormGenerator, ElementStore } from 'react-form-builder2'
import { withRouter } from 'react-router'

var form_id, patient_id, row_id, formStatus, jwt_token
const url = process.env.REACT_APP_BASE_URL

class PreviewForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      forms: null,
      error: null,
      isLoaded: false,
      items: null,
      itemsAnswer: null,
      fId: null,
      fTitle: '',
      toast: false,
      disableButton: false,
      toastMsg: '',
      toastColor: 'green',
    }
  }
  _onSubmit = (data) => {
    const answer = JSON.stringify(data)
    this.setState({
      disableButton: true,
    })
    // Place code to post json data to server here
    if (formStatus === 'update') {
      const requestOptions = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json', 'x-access-token': jwt_token },
        body: JSON.stringify({ answer }),
      }
      fetch(`${url}patient_answers?id=${row_id}`, requestOptions)
        .then((response) => response.json())
        .then((result) => {
          this.setState({
            toast: true,
            toastMsg: result.success ? 'Form submitted successfully!' : result.message,
            toastColor: result.success ? 'green' : 'red',
            disableButton: false,
          })
        })
    } else if (formStatus === 'add') {
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'x-access-token': jwt_token },
        body: JSON.stringify({ form_id, patient_id, answer }),
      }
      try {
        const res = fetch(`${url}patient_answers`, requestOptions)
          .then((response) => response.json())
          .then((result) => {
            this.setState({
              toast: true,
              toastMsg: result.success ? 'Form submitted successfully!' : result.message,
              toastColor: result.success ? 'green' : 'red',
              disableButton: false,
            })
          })
      } catch (e) {
        console.error(e.message)
      }
    }
    // setTimeout(() => {
    //   this.setState({
    //     toast: false,
    //   })
    // }, 3000)
  }

  componentDidMount() {
    const { id, pid, status, token } = this.props.match.params
    form_id = id
    patient_id = pid
    row_id = pid
    formStatus = status
    jwt_token = token

    fetch(`${url}forms?id=${id}`, {
      headers: { 'x-access-token': token },
    })
      .then((response) => response.json())
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            fId: id,
            fTitle: result.data[0].form_title,
          })
          if (result.data[0].form_details) {
            this.setState({
              items: result.data[0].form_details.task_data,
            })
          }
        },

        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            isLoaded: true,
            error,
          })
        },
      )
    if (formStatus === 'update') {
      fetch(`${url}patient_answers?id=${row_id}`, {
        headers: { 'x-access-token': token },
      })
        .then((response) => response.json())
        .then(
          (result) => {
            if (result.data[0].answer) {
              this.setState({
                itemsAnswer: result.data[0].answer,
              })
            }
          },

          // Note: it's important to handle errors here
          // instead of a catch() block so that we don't swallow
          // exceptions from actual bugs in components.
          (error) => {
            this.setState({
              isLoaded: true,
              error,
            })
          },
        )
    }
  }
  render() {
    return (
      <>
        {this.state.items === null && (
          <div>
            <b>Empty Form</b>
          </div>
        )}
        {((this.state.items && (formStatus === 'add' || formStatus === 'preview')) ||
          (this.state.items && this.state.itemsAnswer && formStatus === 'update')) && (
          <div>
            <h1>Title {this.state.fTitle} </h1>

            <ReactFormGenerator
              download_path=""
              answer_data={this.state.itemsAnswer}
              action_name="Save"
              form_method="POST"
              onSubmit={this._onSubmit}
              variables={this.props.variables}
              data={this.state.items}
              submitButton={
                <>
                  <button
                    type="submit"
                    className="btn btn-primary my-3"
                    disabled={this.state.disableButton}
                  >
                    {this.state.disableButton ? 'loading...' : 'submit'}
                  </button>
                  {this.state.toast && (
                    <div className="mx-3" style={{ alignSelf: 'center' }}>
                      <p style={{ color: this.state.toastColor, fontWeight: 'bold' }}>
                        {this.state.toastMsg}
                      </p>
                    </div>
                  )}
                </>
              }
              actionName="Set this to change the default submit button text"
            />
          </div>
        )}
        {/* {this.state.toast && ( */}
        {/* <CToast visible={true} delay={1500} color={'success'} className="align-items-end mt-3 ">
          <div>
            <CToastBody>تمت العملية بنجاح</CToastBody>
          </div>
        </CToast> */}
        {/* // )} */}
      </>
    )
  }
}

export default withRouter(PreviewForm)
